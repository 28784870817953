<template>
  <div id="app">
    <!-- <div class="homeDownload-list" v-if="title!='产品下载'">
      <div class="homeDownload-list-right">
        <div :style="{borderRight:downloadtitle==item.title?'2PX solid #92D5DE':'none'}" v-for="(item,index) in downloadList" :key="index" @mouseenter="downloadClick(item.title)" @mouseleave="downloadLeave">
          <div class="homeDownload-list-right-code" v-if="downloadtitle==item.title">
            <img :src="$imgUrl+'home/code.png'" alt="" srcset="">
          </div>
          <img :src="item.img" alt="">
          <p>{{ item.title }}</p>
        </div>

      </div>
    </div> -->
    <div class="navBox">
      <div class="nav">
        <div class="nav-left">
          <img :src="$imgUrl+'logo.png'" alt="">
          <img :src="$imgUrl+'home/title.png'" alt="">
          <!-- <p>述宠</p> -->
        </div>
        <div class="nav-right">
          <router-link :to="{name:item.name}" v-for="item in navList" :key="item.title">
              <div @click="navChange(item.title)" :style="{ backgroundColor:title==item.title?'#92D5DE':'#fff', color:title==item.title?'#fff':'#000'
              ,fontFamily:title==item.title?'SourceHanSansCN-Medium':'',}">{{ item.title }}</div>
          </router-link>
        </div>
      </div>
    </div>
    <!-- <router-view class="paddTop" @goEnd="goEnd" :navTitle="title" @navTitleChange="navChange" @downloadClick="downloadClick"></router-view> -->
    <router-view class="paddTop" @goEnd="goEnd" :navTitle="title" @downloadClick="downloadClick"></router-view>

    <div class="homeFooter" v-if="flag">
      <div class="homeFooter-left">
        <div class="homeFooter-left-top">
          <div class="homeFooter-left-top-left">
            <img :src="$imgUrl+'home/footerLogo.png'" alt="" srcset="">
          </div>
          <div class="homeFooter-left-top-right">
            <p>述宠</p>
            <p>科学养宠 · 记录宠物点点滴滴</p>
          </div>
        </div>
        <div class="homeFooter-left-center">
          <div class="homeFooter-left-center-item" v-for="(item,index) in footerList" :key="index">
            <img v-if="footerDownloadHoverIndex!=item.index" @mouseenter="footerDownloadHover(item.index)" :src="item.img" alt="" class="homeFooter-left-center-item-img">
            <img v-else :src="item.imgCheck" alt="" srcset="" class="homeFooter-left-center-item-img" @mouseleave="footerDownloadLeave">
            <div class="homeFooter-left-center-item-pop">
              <!-- <img :src="$imgUrl+'home/code.png'" alt=""> -->
              <img :src="item.code" alt="">
              <p>{{ item.title }}</p>
            </div>
          </div>
        </div>
        <div class="homeFooter-left-bottom">
          <div class="homeFooter-left-bottom-list">
              <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">闽ICP备2022010263号-5</a>
          </div>
          <div class="homeFooter-left-bottom-list">
            <div>
              <img src="../../assets/icon.png" alt="">
              <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35021102001744&token=cf01ed85-4879-4bb9-8167-c6038d5d3e1f">闽公网安备35021102001744号</a>
            </div>
          </div>
          <div class="homeFooter-left-bottom-list">
              <a @click="open">增值电信业务经营许可证：闽B2-20221070</a>
          </div>
          <div class="homeFooter-left-bottom-list">
            <p>Copyright 2020-2023 Qiansay 版权所有</p>
          </div>
        </div>
      </div>
      <div class="homeFooter-right">
        <div class="homeFooter-right-top">
          <!-- <router-link :to="{name:item.name}" v-for="item in navList" :key="item.title">
            <div @click="navChange(item.title)" class="homeFooter-right-top-item">
              <p>{{ item.title }}</p>
              <div></div>
            </div>
          </router-link> -->
            <div @click="navTo(item.title, item.name)" v-for="item in navList" :key="item.title" class="homeFooter-right-top-item">
              <p>{{ item.title }}</p>
              <div></div>
            </div>
        </div>

      </div>
    </div>
  </div>
</template>
  <script>
  import imageUrl from "@/assets/imageUrl.png"
export default {
  data() {
    return {
      footerDownloadHoverIndex: -1,
      flag: false,
      swiperHeight: document.body.clientHeight - 80 + 'PX',
      swiperWidth: document.body.clientWidth + 'PX',
      title: '首页',
      name: 'home',
      navList: [
        {
          title: '首页',
          name: 'Home'
        },
        {
          title: '产品介绍',
          name: 'Introduce'
        },
        {
          title: '防丢牌',
          name: 'LostCard'
        },
        {
          title: '养宠知识',
          name: 'Knowledge'
        },
        {
          title: '关于我们',
          name: 'About'
        },
        {
          title: '产品下载',
          name: 'Download'
        },
      ],
      downloadtitle: '',
      downloadList: [
        { img: this.$imgUrl+ 'home/wx.png', title: '小程序' },
        { img: this.$imgUrl+ 'home/android.png', title: '安卓' },
        { img: this.$imgUrl+ 'home/apple.png', title: 'ios' },
      ],
      code: require('../../assets/home/code.png'),
      footerList: [
        {
          index: 0,
          img: this.$imgUrl+ 'home/1.png',
          imgCheck: this.$imgUrl+ 'home/1check.png',
          title: '扫码进入微信小程序',
          // code: this.$imgUrl+ 'home/code.png'
          code: require('../../assets/home/MiniCode.png')
        },
        {
          index: 1,
          img:this.$imgUrl+ 'home/2.png',
          imgCheck: this.$imgUrl+ 'home/2check.png',
          title: '扫码下载述宠app',
          // code:this.$imgUrl+ 'home/code.png'
          code: require('../../assets/home/index/androidDownLoadCode.png')

        }, {
          index: 2,
          img:this.$imgUrl+ 'home/3.png',
          imgCheck: this.$imgUrl+ 'home/3check.png',
          title: '扫码下载述宠app',
          // code: this.$imgUrl+ 'home/code.png'
          code: require('../../assets/home/index/androidDownLoadCode.png')

        },
        {
          index: 3,
          img: this.$imgUrl+ 'home/4.png',
          imgCheck: this.$imgUrl+ 'home/4check.png',
          title: '扫码进入述宠公众号',
          // code: this.$imgUrl+ 'home/code.png'
          code: require('../../assets/home/official-code.png')

        },
      ],
    }

  },
  mounted() {
    this.swiperHeight = document.body.clientHeight - 80
   
  },
  methods: {
    open() {
      const image = new Image()
      image.src = imageUrl
      image.onload = () => {
        image.style.margin = "0 auto"
        image.style.display = "block"
        const newWin = window.open("", "_blank")
        newWin.document.write(image.outerHTML)
      }
    },
    footerDownloadHover(index) {
      this.footerDownloadHoverIndex = index
    },
    footerDownloadLeave() {
      this.footerDownloadHoverIndex = -1
    },
    goEnd(flag) {
      this.flag = flag
      // console.log(flag, "1111")
    },
    navChange(title) {
      console.log(title);
      this.title = title
      this.flag = false
      window.scrollTo(0, 0);
    },
    navTo(title, name) {
      this.title = title
      this.name = name
      this.$router.push({name: this.name})
      window.scrollTo(0, 0);
    },
    downloadClick(downloadtitle) {
      if (this.downloadtitle != downloadtitle) {
        this.downloadtitle = downloadtitle
      } else {
        this.downloadtitle = ''
      }
    },
    downloadLeave() {
      this.downloadtitle = ''
    },
  }
}
</script>
  
  <style lang="scss">
   img[lazy="loading"] {
		width: 400PX !important;
		height: 50PX !important;
	}
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
html,
body {
  height: 100%;
}
.paddTop {
  padding-top: 52PX;
}
* {
  margin: 0;
  padding: 0;
  font-size: 14PX;
}
a {
  text-decoration: none;
}
html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 14PX;
}
.homeDownload-list {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
  margin-right: 43PX;
  position: fixed;
  right: 0PX;
  z-index: 1000;
  .homeDownload-list-right {
    box-shadow: 0PX 5PX 19PX 4PX rgba(123, 200, 210, 0.18);
    background-color: #fff;
    width: 54PX;
    height: 300PX;
    border-radius: 27PX;
    // margin-left: 30PX;
    // margin-top: -120PX;
    display: flex;
    flex-direction: column;
    align-items: center;
    div {
      width: 100%;
      margin-top: 37PX;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // padding-right: 14PX;
      padding-right: -2PX;
      img {
        width: 31PX;
        height: 31PX;
      }
      p {
        font-size: 10PX;
        color: #7bc8d2;
        margin-top: 8PX;
        font-weight: bold;
      }
      .homeDownload-list-right-code {
        width: 110PX;
        height: 110PX;
        border-radius: 10PX;
        background-color: #fff;
        // margin-bottom: 19PX;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: -138PX;
        box-shadow: 0PX 7PX 26PX 6PX rgba(123, 200, 210, 0.18);
        // box-shadow: 0PX 0PX 18PX 7PX rgba($color: #7bc8d2, $alpha: 0.18);
        img {
          width: 90PX;
          height: 90PX;
          // margin-left: 10PX;
        }
      }
    }
  }
}
.navBox {
  width: 100%;
  // height: 80PX;
  background-color: #fff;
  // box-sizing: border-box;
  z-index: 1200;
  position: fixed;
  border-bottom: 0.5PX solid #f6f6f6;
  top: 0PX;
  left: 0PX;
  right: 0PX;
  display: flex;
  align-items: center;
  justify-content: center;
  // min-width:1670PX;
}
.nav {
  // min-width:1670PX;
  display: flex;
  // justify-content: space-between;
  align-items: center;
  // width: 100%;
  height: 52PX;
  position: relative;
  // top: 0;
  align-items: center;

  .nav-left {
    display: flex;
    align-items: center;
    // margin-left: 200PX;
    img:first-child {
      width: 44PX;
      height: 44PX;
      margin-right: 8PX;
    }
    img:last-child {
      width: 50PX;
      height: 21PX;
    }
    // p {
    //   font-size: 40PX;
    //   color: #88bfc6;
    //   font-family: PangMenZhengDao;
    //   font-weight: 0PX;
    // }
  }
  .nav-right {
    // margin-right: 180PX;
    display: flex;
    margin-left: 563PX;
    a:first-child div {
      margin-left: 0PX;
    }
    div {
      border-radius: 16PX;
      padding-left: 18PX;
      padding-right: 18PX;
      height: 32PX;
      margin-left: 61PX;
      font-size: 16PX;
      color: #000;
      display: flex;
      align-items: center;
    }
  }
}
.homeFooter {
  z-index: 2;
  position: relative;
  // min-width: 1680PX;
  // min-width: 1920PX;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 36PX;
  padding-bottom: 36PX;
  background-color: #f7f9fa;

  .homeFooter-left {
    display: flex;
    flex-direction: column;
    margin-right: 208PX;
    .homeFooter-left-top {
      display: flex;
      .homeFooter-left-top-left {
        img {
          width: 40PX;
          height: 40PX;
        }
      }
      .homeFooter-left-top-right {
        display: flex;
        flex-direction: column;
        margin-left: 10PX;
        p:first-child {
          color: #000000;
          font-size: 19PX;
          font-family: 'SourceHanSansCN-Medium';
          margin-top: -4PX;
          margin-bottom: 0PX;
        }
        p:last-child {
          color: #333333;
          font-size: 12PX;
          // zoom: 0.75;
        }
      }
    }
    .homeFooter-left-center {
      display: flex;
      margin-top: 15PX;
      margin-bottom: 25PX;
      .homeFooter-left-center-item:hover .homeFooter-left-center-item-pop {
        // opacity: 1;
        display: block;
      }
      .homeFooter-left-center-item {
        margin-right: 33PX;
        position: relative;
        .homeFooter-left-center-item-img {
          width: 16PX;
          height: 16PX;
        }
        .homeFooter-left-center-item-pop {
          position: absolute;
          background-image: url("http://testshop.ichongapp.com/home/footerImg.png");
          // width: 147PX;
          // height: 167PX;
          width: 167PX;
          height: 187PX;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          // opacity: 0;
          bottom: 10PX;
          right: -100PX;
          display: none;

          img {
            // width: 90PX;
            // height: 90PX;
            width: 110PX;
            height: 110PX;
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          p {
            // zoom: 0.75;
            font-size: 12PX;
            color: #333;
            margin-top: 10PX;
            position: absolute;
            top: 70%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
    .homeFooter-left-bottom {
      display: flex;
      flex-direction: column;
      .homeFooter-left-bottom-list:first-child {
        margin-top: 0PX;
      }
      .homeFooter-left-bottom-list {
        display: flex;
        align-items: center;
        margin-top: 14PX;
        div{
          display: flex;
          align-items: center;
          margin-right: 22PX;
          img{
            width: 20PX;
            height: 20PX;
            margin-right: 3PX;
          }
        }
        p:first-child,a:first-child {
          margin-right: 22PX;
        }
        p,a {
          color: #999999;
          font-size: 12PX;
          letter-spacing: 1.2PX;
          cursor: pointer;
        }
      }
    }
  }
  .homeFooter-right {
    margin-top: 25PX;
    margin-left: 44PX;
    .homeFooter-right-top {
      display: flex;
      .homeFooter-right-top-item {
        // border-right: 1PX solid #999999;
        // padding-right: 10PX;
        padding-left: 8PX;
        display: flex;
        align-items: center;
        div {
          width: 1PX;
          height: 10PX;
          background-color: #999999;
          margin-left: 8PX;
        }
        // div:last-child{
        //   width: 0PX;
        // }
        p {
          color: #999999;
          font-size: 12PX;
          // font-weight: bold;
        }
        p:hover {
          color: #c6c6c6;
        }
      }
      a:last-child .homeFooter-right-top-item div {
        width: 0PX;
      }
    }
  }
}
</style>
  